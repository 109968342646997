import ImageCompare from "image-compare-viewer";

class CustomImageCompare extends ImageCompare {
  // overwrite 一些方法
  // 原本的使用情境是 after 會顯示比例再蓋在 before 上
  // ex: --------------  <- before
  //             ======  <= after

  // 調整成，被蓋住的 before 不會顯示，這樣如果 after 是去背的話才不會穿幫
  // ex: --------        <- before
  //             ======  <= after
  constructor(el, options) {
    super(el, options);

    this.beforeImage = null;
  }

  _slideCompare(ev) {
    super._slideCompare(ev)

    let bounds = this.el.getBoundingClientRect();
    let x =
      ev.touches !== undefined
        ? ev.touches[0].clientX - bounds.left
        : ev.clientX - bounds.left;
    let y =
      ev.touches !== undefined
        ? ev.touches[0].clientY - bounds.top
        : ev.clientY - bounds.top;

    let position = this.settings.verticalMode
      ? (y / bounds.height) * 100
      : (x / bounds.width) * 100;

    let positionCeil = Math.ceil(position * 10) / 10

    this.beforeImage.style.clipPath = `inset(0 ${100 - positionCeil}% 0 0)`
  }

  _getImages() {
    super._getImages()
    this.beforeImage = document.querySelector('.icv__img-a');
    this.beforeImage.style.clipPath = `inset(0 ${100 - this.settings.startingPoint}% 0 0)`
  }
}


export const initImageCompare = () => {
  const viewers = document.querySelectorAll(".image-compare");

  viewers.forEach((element) => {
    const options = {

      // UI Theme Defaults

      controlColor: "#FFFFFF",
      controlShadow: true,
      addCircle: false,
      addCircleBlur: true,

      // Label Defaults

      showLabels: false,
      labelOptions: {
        before: 'Before',
        after: 'After',
        onHover: false
      },

      // Smoothing
      smoothing: false,

      // Other options

      hoverStart: false,
      verticalMode: false,
      startingPoint: 49.1,
      fluidMode: false
    };

    const viewer = new CustomImageCompare(element, options)

    viewer.mount();
  });
}